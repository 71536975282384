import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [0,4,5,6];

export const dictionary = {
		"/auth/auth-code-error": [7,[3]],
		"/auth/login": [8,[3]],
		"/auth/reset-password": [~9,[3]],
		"/auth/reset-password/reset-confirm": [10,[3]],
		"/dashboard": [11,[4]],
		"/dashboard/[companyId]": [~12,[4,5]],
		"/dashboard/[companyId]/account": [13,[4,5]],
		"/dashboard/[companyId]/admins": [~14,[4,5]],
		"/dashboard/[companyId]/community": [~15,[4,5]],
		"/dashboard/[companyId]/edit": [16,[4,5]],
		"/dashboard/[companyId]/feedback": [17,[4,5]],
		"/dashboard/[companyId]/integrations": [~18,[4,5]],
		"/dashboard/[companyId]/integrations/webhooks": [~19,[4,5]],
		"/dashboard/[companyId]/integrations/webhooks/[id]": [~20,[4,5]],
		"/dashboard/[companyId]/posts": [21,[4,5]],
		"/dashboard/[companyId]/posts/create": [29,[4,5]],
		"/dashboard/[companyId]/posts/[postId]": [22,[4,5,6]],
		"/dashboard/[companyId]/posts/[postId]/applications": [23,[4,5,6]],
		"/dashboard/[companyId]/posts/[postId]/applications/recommendations": [~25,[4,5,6]],
		"/dashboard/[companyId]/posts/[postId]/applications/search": [~26,[4,5,6]],
		"/dashboard/[companyId]/posts/[postId]/applications/[userId]": [~24,[4,5,6]],
		"/dashboard/[companyId]/posts/[postId]/edit": [27,[4,5,6]],
		"/dashboard/[companyId]/posts/[postId]/questions": [28,[4,5,6]],
		"/dashboard/[companyId]/profile": [30,[4,5]],
		"/dashboard/[companyId]/search": [31,[4,5]],
		"/dashboard/[companyId]/stats": [32,[4,5]],
		"/dashboard/[companyId]/templates": [33,[4,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';